import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import PageHeading from '../components/PageHeading';
import PostsList from '../components/PostsList';

const Posts = props => {
  const {
    data: {
      allMarkdownRemark: { edges }
    }
  } = props;

  return (
    <Layout title="Posts">
      <header>
        <PageHeading>
          Posts{' '}
          <span className="ml-2 text-gray-600 text-xl">{edges.length}</span>
        </PageHeading>
      </header>
      <PostsList edges={edges} />
    </Layout>
  );
};

Posts.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array.isRequired
    }).isRequired
  }).isRequired
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/posts/" }
        fields: { listed: { eq: true } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          timeToRead
          frontmatter {
            title
            date
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default Posts;
